<script>
import Icon from '@/components/Icon'

export default {
  name: 'Home',

  components: {
    Icon,
  },

  mounted () {

  },
}
</script>

<template>
  <div :class="$classes['home']">
    <h1 :class="$classes['title']">Seja bem-vindo(a) ao Sistema Scherer para Autopeças.</h1>
    <p :class="$classes['message']">Veja como fazer seu pedido:</p>
    <ul :class="$classes['list']" style="padding: 0;">
      <li :class="[$classes['list-item'], $classes['arrow-right']]">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="search-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">
            1
            <span :class="$classes['list-number-text']">Início</span>
          </span>
          <span :class="$classes['list-text']">Busque os <br>produtos</span>
        </div>
      </li>
      <li :class="[$classes['list-item'], $classes['arrow-right']]">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="clipboard-list-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">2</span>
          <span :class="$classes['list-text']">Adicione <br>aos pedidos</span>
        </div>
      </li>
      <li :class="[$classes['list-item'], $classes['line-down'], $classes['arrow-long']]">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="share-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">3</span>
          <span :class="$classes['list-text']">Finalize <br>o pedido</span>
        </div>
      </li>
      <li :class="[$classes['list-item'], $classes['arrow-right'], $classes['arrow-down']]">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="shipping-fast-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">4</span>
          <span :class="$classes['list-text']">Aguarde a <br>entrega</span>
        </div>
      </li>
      <li :class="[$classes['list-item'], $classes['arrow-right']]">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="receipt-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">5</span>
          <span :class="$classes['list-text']">Receba a fatura <br>e nota fiscal</span>
        </div>
      </li>
      <li :class="$classes['list-item']">
        <div :class="$classes['list-icon-wrapper']">
          <icon name="invoice-gradient" :class="$classes['list-icon']"/>
        </div>
        <div :class="$classes['list-info']">
          <span :class="$classes['list-number']">6</span>
          <span :class="$classes['list-text']">Realize o <br>pagamento <br>do boleto</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style module>
.home {
  height: 100%;
  background: url('~@/assets/images/home.png') no-repeat center;
  background-size: cover;
  padding: 30px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 25px;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #fff;
  margin-bottom: 40px;
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 780px;
}

.list-item {
  display: flex;
  align-items: center;
  width: 257px;
  position: relative;
}

.list-item:nth-child(1),
.list-item:nth-child(2),
.list-item:nth-child(3) {
  margin-bottom: 100px;
}

.list-item.arrow-right:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100px;
  margin-top: -4px;
  background: url('~@/assets/images/arrow.svg') no-repeat;
  width: 158px;
  height: 8px;
}

.list-item.line-down:before {
  content: '';
  position: absolute;
  top: 105px;
  left: 50px;
  background: url('~@/assets/images/line-down.svg') no-repeat;
  width: 1px;
  height: 44px;
}

.list-item.arrow-long:after {
  content: '';
  position: absolute;
  top: 148px;
  left: -464px;
  background: url('~@/assets/images/arrow-long.svg') no-repeat;
  width: 514px;
  height: 1px;
}

.list-item.arrow-down:before {
  content: '';
  position: absolute;
  top: -62px;
  left: 46px;
  background: url('~@/assets/images/arrow-down.svg') no-repeat;
  width: 8px;
  height: 67px;
}

.list-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: rgba(255,255,255,0.1);
  border-radius: 100px;
  margin-right: 12px;
}

.list-icon {
  width: 45px;
  height: 45px;
}

.list-number {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: #C79653;
}

.list-number-text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #C79653;
  margin-left: 5px;
}

.list-text {
  display: flex;
  height: 50px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.1em;
}

@media (max-width: 1030px) {
  .list {
    display: block;
  }

  .list-item,
  .list-item:nth-child(1),
  .list-item:nth-child(2),
  .list-item:nth-child(3) {
    margin-bottom: 40px;
  }

  .list-item.line-down:before,
  .list-item.arrow-right:after,
  .list-item.arrow-long:after,
  .list-item.arrow-down:before {
    display: none;
  }

  .list .list-item:after {
    display: block;
    content: '';
    position: absolute;
    top: 108px;
    left: 46px;
    background: url('~@/assets/images/arrow-down-small.svg') no-repeat center/cover;
    width: 9px;
    height: 50px;
  }

  .list .list-item:last-child:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .home {
    padding: 20px;
  }

  .title {
    text-align: center;
  }
}
</style>
